import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CircleDot, CheckCircle2, XCircle, ArrowLeft } from 'lucide-react';
import { Button } from '@/components/ui/button';
import api from '@/config/axios';

const ConnectCallback = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState('initializing');
  const [currentStep, setCurrentStep] = useState(0);
  const [error, setError] = useState(null);

  const code = searchParams.get('code');
  const state = searchParams.get('state'); // platform identifier
  const error_param = searchParams.get('error');

  const steps = [
    { id: 'auth', title: 'Verifying Authorization' },
    { id: 'token', title: 'Establishing Connection' },
    { id: 'services', title: 'Configuring Services' },
    { id: 'complete', title: 'Completing Setup' }
  ];

  useEffect(() => {
    const connectAccount = async () => {
      if (error_param) {
        setStatus('error');
        setError('Authentication was denied or cancelled.');
        return;
      }

      if (!code) {
        setStatus('error');
        setError('No authorization code received.');
        return;
      }

      try {
        // Step 1: Verify Authorization
        setCurrentStep(0);
        setStatus('processing');
        await new Promise(resolve => setTimeout(resolve, 500)); // Simulate verification

        // Step 2: Exchange code for tokens
        setCurrentStep(1);
        await new Promise(resolve => setTimeout(resolve, 500));
        setCurrentStep(2);

        const tokenResponse = await api.post(`/integrations/google/callback`, { code, state });

        if (tokenResponse){
          // Step 4: Complete Setup
          setCurrentStep(3);
          await new Promise(resolve => setTimeout(resolve, 500));

          setStatus('success');
          setTimeout(() => {
            navigate(`/integrations`);
          }, 1000);
        }
      } catch (err) {
        setStatus('error');
        setError(err.response?.data?.message || 'Failed to connect account. Please try again.');
      }
    };

    connectAccount();
  }, [code, state, error_param]);

  return (
    <div className="min-h-screen bg-gray-50/30 flex items-center justify-center p-4">
      <div className="w-full max-w-md">
        <div className="bg-background rounded-lg shadow-lg p-6">
          {/* Header */}
          <div className="text-center mb-8">
            <h1 className="text-2xl font-semibold mb-2">
              {status === 'success' ? 'Connection Successful!' :
                status === 'error' ? 'Connection Failed' :
                  'Connecting Your Account'}
            </h1>
            <p className="text-muted-foreground">
              {status === 'success' ? 'Your account has been connected successfully' :
                status === 'error' ? 'There was a problem connecting your account' :
                  'Please wait while we set up your integration'}
            </p>
          </div>

          {/* Progress Steps */}
          <div className="space-y-4 mb-8">
            {steps.map((step, index) => (
              <div key={step.id} className="flex items-center">
                <div className="flex-shrink-0 w-8 h-8 flex items-center justify-center">
                  {status === 'error' && currentStep === index ? (
                    <XCircle className="w-6 h-6 text-destructive" />
                  ) : status === 'success' || currentStep > index ? (
                    <CheckCircle2 className="w-6 h-6 text-primary" />
                  ) : currentStep === index ? (
                    <CircleDot className="w-6 h-6 text-primary animate-pulse" />
                  ) : (
                    <div className="w-6 h-6 rounded-full border-2 border-muted" />
                  )}
                </div>
                <div className="ml-4 flex-1">
                  <div className="flex items-center justify-between">
                    <span className={`text-sm font-medium ${currentStep === index ? 'text-primary' : 'text-muted-foreground'}`}>
                      {step.title}
                    </span>
                    {currentStep === index && status === 'processing' && (
                      <span className="text-xs text-muted-foreground animate-pulse">
                        In Progress...
                      </span>
                    )}
                  </div>
                  {index < steps.length - 1 && (
                    <div className="ml-3 mt-1 mb-1 w-px h-6 bg-border" />
                  )}
                </div>
              </div>
            ))}
          </div>

          {/* Error Message */}
          {status === 'error' && (
            <div className="bg-destructive/10 text-destructive rounded-lg p-4 mb-6">
              <p className="text-sm">{error}</p>
            </div>
          )}

          {/* Actions */}
          <div className="flex justify-center">
            {(status === 'success' || status === 'error') && (
              <Button
                onClick={() => navigate('/integrations')}
                className="flex items-center"
              >
                <ArrowLeft className="w-4 h-4 mr-2" />
                {status === 'success' ? 'Back to Integrations' : 'Try Again'}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectCallback;
