import React, { useState } from "react";
import { LogOut, Settings, User } from "lucide-react";
import { Link as RouterLink } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext.jsx";

const ProfileDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { logout, user } = useAuth();
  const toggleDropdown = () => setIsOpen(!isOpen);
  const makeAvatar = (name) => {
    return name
      .split(" ")
      .map((n) => n[0])
      .join("")
      .toUpperCase();
  };

  console.log();

  return (
    <div className="relative">
      {/* Profile Button */}

      <button
        onClick={toggleDropdown}
        className={`flex items-center justify-center w-10 h-10 rounded-full ${!user?.avatar ? "bg-gray-800  focus:outline-none" : ""} text-white`}
      >
        {/* Avatar */}
        {user?.avatar ? (
          <img
            className={`w-8 h-8 rounded-full`}
            src={user?.avatar}
            alt="avatar"
          />
        ) : (
          <span className="text-white font-medium">
            {makeAvatar(`${user?.firstName} ${user?.lastName}`)}
          </span>
        )}
      </button>

      {/* Dropdown Menu */}
      {isOpen && (
        <div className="absolute left-0 mt-2 w-auto bg-white rounded-lg shadow-lg border border-gray-200">
          {/* User Info Section */}
          <div className="p-4 border-b border-gray-200">
            <h3 className="text-sm font-semibold text-gray-900">{`${user?.firstName} ${user?.lastName}`}</h3>
            <p className="text-sm text-gray-500">{user?.email}</p>
          </div>

          {/* Menu Items */}
          <div className="py-2">
            <RouterLink
              to={`/dashboard`}
              className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 flex items-center gap-2"
            >
              <User size={16} />
              Dashboard
            </RouterLink>
            <RouterLink to={`/settings`} className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 flex items-center gap-2">
              <Settings size={16} />
              Settings
            </RouterLink>
            <div className="border-t border-gray-200 my-1"></div>
            <button
              onClick={logout}
              className="w-full px-4 py-2 text-left text-sm text-red-600 hover:bg-gray-100 flex items-center gap-2"
            >
              <LogOut size={16} />
              Sign out
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileDropdown;
