export const publicRoutes = [
  '/',
  '/features',
  '/pricing',
  '/verify-email',
  '/help',
  '/documentation',
  '/community',
  '/integration',
  '/contact',
  '/career',
  '/privacy',
  '/terms',
  '/status',
  '/security',
  '/blog',
  '/about',
  '/enterprise',
]
