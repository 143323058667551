import axios from 'axios';
import {DeviceUtils} from "../lib/device-utils.js";
import {SessionUtils} from "../lib/sassion-management.js";
import {googleLogout} from "@react-oauth/google";
import {setAPIUrl} from "@/utils/common.js";

// Create axios instance with default config

const api = axios.create({
  baseURL: `${setAPIUrl()}/api/v1`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: false,
  timeout: 30000,
});

// Request interceptor for adding auth token
api.interceptors.request.use(
  (config) => {
    const session = SessionUtils.getSession();
    if (session?.token) {
      config.headers.Authorization = `Bearer ${session?.token}`;
    }
    const deviceInfo = DeviceUtils.getDeviceInfo();
    config.headers['Device-Info'] = JSON.stringify(deviceInfo);

    return config;
  },
  (error) => {
    return Promise.reject(error.response?.data || error);
  }
);

// Response interceptor for handling token refresh, errors, and response normalization
api.interceptors.response.use(
  (response) => {
    // Normalize the response to directly return the data
    return response.data?.data || response.data || response;
  },
  async (error) => {
    const originalRequest = error.config;
    const session = SessionUtils.getSession();

    // If error is 401 and we haven't tried to refresh token yet
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!session?.refreshToken) {
        throw error.response?.data || error;
      }

      try {
        const response = await api.post('/auth/refresh-token', {
          refreshToken: session.refreshToken
        });

        // Update session with new tokens
        SessionUtils.setSession(
          response.token,
          response.refreshToken,
          session.isRemembered
        );

        // Retry the original request with new token
        originalRequest.headers.Authorization = `Bearer ${response.token}`;
        return api(originalRequest);
      } catch (refreshError) {
        SessionUtils.clearSession();
        window.location.href = '/login';
        throw refreshError;
      }
    }

    // Normalize error response
    throw error.response?.data || error;
  }
);

export const tokenManager = {
  refreshTimer: null,

  startAutoRefresh() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }

    this.refreshTimer = setInterval(async () => {
      try {
        const session = SessionUtils.getSession();
        if (!session) return;

        const response = await api.post('/auth/refresh-token', {
          refreshToken: session.refreshToken
        });

        SessionUtils.setSession(
          response.token,
          response.refreshToken,
          session.isRemembered
        );
      } catch (error) {
        console.error('Token refresh failed:', error);
        SessionUtils.clearSession();
        window.location.href = '/login';
      }
    }, 7 * 60 * 60 * 1000);
  },

  stopAutoRefresh() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }
};

// Auth related API calls
export const authAPI = {
  login: async (email, password, rememberMe) => {
    const data = await api.post('/auth/login', { email, password });
    SessionUtils.setSession(data?.tokens?.accessToken, data?.tokens?.refreshToken, rememberMe);
    // tokenManager.startAutoRefresh();
    return data;
  },

  google: async (userData) => {
    const data = await api.post('/auth/google', userData);
    SessionUtils.setSession(data?.tokens?.accessToken, data?.tokens?.refreshToken, true);
    // tokenManager.startAutoRefresh();
    return data;
  },

  register: async (userData) => {
    const data = await api.post('/auth/register', userData);
    SessionUtils.setSession(data?.tokens?.accessToken, data?.tokens?.refreshToken, true);
    // tokenManager.startAutoRefresh();
    return data;
  },

  verifyToken: async () => {
    return api.get('/auth/verify');
  },

  logout: async () => {
    const data = await api.post('/auth/logout');
    tokenManager.stopAutoRefresh();
    SessionUtils.clearSession();
    return data;
  },

  clearGoogleSession: () => {
    localStorage.removeItem('taskweave_user');
    googleLogout();
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
  },

  checkAccount: async (email) => {
    return api.get(`/auth/check-account?email=${email}`);
  }
};

export default api;
