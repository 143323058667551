import Header from "../components/common/layout/public/header.jsx";
import React from "react";
import Footer from "../components/common/layout/public/footer.jsx";

const PublicLayout = ({ children }) => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Header/>
      {children}
      <Footer/>
    </div>
  );
};

export default PublicLayout;
