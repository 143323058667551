import React from "react";
import {
  Calendar,
  CheckSquare,
  HelpCircle,
  MessageSquare,
  Settings,
  Zap,
  Link,
  LayoutDashboard,
  ChartNoAxesCombined,
} from "lucide-react";
import GoogleCallback from "@/pages/public/GoogleCallBack.jsx";
import ConnectCallback from "@/pages/integration/integration.callback.jsx";
import MeetingSettings from "@/pages/settings/meetings.jsx";

// Lazy load page components
const Dashboard = React.lazy(() => import("../pages/dashboard"));
const Tasks = React.lazy(() => import("@/pages/tasks"));
const TasksBoard = React.lazy(() => import("@/pages/tasks/simple/important.jsx"));
const TasksCalender = React.lazy(() => import("@/pages/tasks/calendar-view"));
const Meetings = React.lazy(() => import("@/pages/meetings"));
const Analytics = React.lazy(() => import("@/pages/analytics"));
const ProfileSettings = React.lazy(() => import("@/pages/settings/profile"));
const NotificationSettings = React.lazy(() => import("@/pages/settings/notifications"));
const SecuritySettings = React.lazy(() => import("@/pages/settings/security"));
const TeamSettings = React.lazy(() => import("@/pages/settings/team"));
const LanguageSettings = React.lazy(() => import("@/pages/settings/language-region"));
const AppearanceSettings = React.lazy(() => import("@/pages/settings/appearance"));
const EmailSettings = React.lazy(() => import("@/pages/settings/email"));
const BillingSettings = React.lazy(() => import("@/pages/settings/billing"));
const Help = React.lazy(() => import("@/pages/help"));
const AutomationPage = React.lazy(() => import("@/pages/automation"));
const Integrations = React.lazy(() => import("@/pages/integration/integrations"));
const LandingPage = React.lazy(() => import("@/pages/public/landing"));
const AuthPage = React.lazy(() => import("@/pages/auth/LoginPage"));
const VerifyEmailPage = React.lazy(
  () => import("@/pages/auth/verifyEmailPage"),
);
const ForgotPasswordPage = React.lazy(
  () => import("@/pages/auth/forgotPasswordPage"),
);
const ResetPasswordPage = React.lazy(
  () => import("@/pages/resetPasswordPage"),
);
const TaskCalendarView = React.lazy(
  () => import("@/pages/calendar"),
);
const OnboardingWelcome = React.lazy(
  () => import("@/pages/onboarding/index"),
);
const RegistrationWizard = React.lazy(
  () => import("@/pages/auth/RegistrationPage"),
);
const FeaturesPage = React.lazy(
  () => import("@/pages/public/FeaturePage"),
);
const PricingPage = React.lazy(() => import("@/pages/public/PricingPage"));
const OnboardingFlow = React.lazy(
  () => import("@/pages/onboarding/Onboarding"),
);
const Unauthorized = React.lazy(() => import("@/pages/auth/unauthorized"));

export const sidebarRoutes = [
  {
    path: "/dashboard",
    icon: LayoutDashboard,
    label: "Dashboard",
    component: Dashboard,
    location: "sideBar",
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: "/calendar",
    icon: Calendar,
    label: "Analytics",
    component: TaskCalendarView,
    location: "sideBar",
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: "/tasks",
    icon: CheckSquare,
    label: "Tasks",
    component: Tasks,
    location: "sideBar",
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: "/events",
    icon: MessageSquare,
    label: "Meetings",
    component: Meetings,
    location: "sideBar",
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: "/integrations",
    icon: Link,
    label: "integrations",
    component: Integrations,
    location: "sideBar",
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: "/automation",
    icon: Zap,
    label: "automation",
    component: AutomationPage,
    location: "sideBar",
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: "/analytics",
    icon: ChartNoAxesCombined,
    label: "Settings",
    component: Analytics,
    location: "sideBar",
    layout: "mainLayout",
    isProtected: true,
  },
];

export const protectedRRoutes = [
  {
    path: "/tasks/board-view",
    icon: CheckSquare,
    label: "Tasks",
    component: TasksBoard,
    location: "sideBar",
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: "/settings",
    icon: Settings,
    label: "Settings",
    component: ProfileSettings,
    location: null,
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: "/tasks/calendar-view",
    icon: CheckSquare,
    label: "Tasks",
    component: TasksCalender,
    location: "sideBar",
    layout: "mainLayout",
    isProtected: true,
  },

  {
    path: "/notifications",
    icon: LayoutDashboard,
    label: "notifications",
    component: NotificationSettings,
    location: null,
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: "/security",
    icon: LayoutDashboard,
    label: "security",
    component: SecuritySettings,
    location: null,
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: "/teams",
    icon: LayoutDashboard,
    label: "teams",
    component: TeamSettings,
    location: null,
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: "/language",
    icon: LayoutDashboard,
    label: "language",
    component: LanguageSettings,
    location: null,
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: "/appearance",
    icon: LayoutDashboard,
    label: "appearance",
    component: AppearanceSettings,
    location: null,
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: "/email-settings",
    icon: LayoutDashboard,
    label: "emailSettings",
    component: EmailSettings,
    location: null,
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: "/billings",
    icon: LayoutDashboard,
    label: "billings",
    component: BillingSettings,
    location: null,
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: "/meeting-settings",
    icon: Calendar,
    label: "meetingSettings",
    component: MeetingSettings,
    location: null,
    layout: "mainLayout",
    isProtected: true,
  },
  {
    path: "/integrations/google/callback",
    icon: LayoutDashboard,
    label: "billings",
    component: ConnectCallback,
    location: null,
    layout: 'none',
    isProtected: true,
  },

]

export const allRoutes = [
  ...sidebarRoutes,
  ...protectedRRoutes,
  {
    path: "/auth/google/callback",
    icon: null,
    label: "GoogleCallback",
    component: GoogleCallback,
    location: null,
    layout: 'none',
    isProtected: false,
  },
  {
    path: "/",
    icon: null,
    label: "HomePage",
    component: LandingPage,
    location: null,
    layout: "none",
    isProtected: false,
  },
  {
    path: "/login",
    icon: null,
    label: "HomePage",
    component: AuthPage,
    location: null,
    layout: "none",
    isProtected: false,
  },
  {
    path: "/register",
    icon: null,
    label: "HomePage",
    component: RegistrationWizard,
    location: null,
    layout: "none",
    isProtected: false,
  },
  {
    path: "/features",
    icon: null,
    label: "HomePage",
    component: FeaturesPage,
    location: null,
    layout: "none",
    isProtected: false,
  },
  {
    path: "/pricing",
    icon: null,
    label: "HomePage",
    component: PricingPage,
    location: null,
    layout: "none",
    isProtected: false,
  },
  {
    path: "/start-now",
    icon: null,
    label: "startTrial",
    component: OnboardingFlow,
    location: null,
    layout: "none",
    isProtected: false,
  },
  {
    path: "/verify-email",
    icon: null,
    label: "verify",
    component: VerifyEmailPage,
    location: null,
    layout: "none",
    isProtected: false,
  },
  {
    path: "/forget-password",
    icon: null,
    label: "verify",
    component: ForgotPasswordPage,
    location: null,
    layout: "auth",
    isProtected: false,
  },
  {
    path: "/reset-password",
    icon: null,
    label: "verify",
    component: ResetPasswordPage,
    location: null,
    layout: "auth",
    isProtected: false,
  },
  {
    path: "/unauthorized",
    icon: null,
    label: "unauthorized",
    component: Unauthorized,
    location: null,
    layout: "none",
    isProtected: false,
  },
  {
    path: "/help",
    icon: HelpCircle,
    label: "help",
    component: Help,
    location: null,
    layout: null,
    isProtected: false,
  },
  {
    path: "/onboarding/welcome",
    icon: null,
    label: "welcome",
    component: OnboardingWelcome,
    location: null,
    layout: "none",
    isProtected: true,
  },
];
