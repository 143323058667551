import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from "../contexts/AuthContext";
import { LoadingSpinner } from "../App";

const ProtectedRoute = ({ children, requiredRole }) => {
  const { isAuthenticated, isLoading, user } = useAuth();
  const location = useLocation();
  const currentPath = location.pathname;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Only check onboarding status for non-onboarding routes
  if (!currentPath.startsWith('/onboarding') && user && !user.isOnboardingDone) {
    return <Navigate to="/onboarding/welcome" replace />;
  }

  if (requiredRole && user?.role !== requiredRole) {
    return <Navigate to="/unauthorized" replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
