import {
    Bell, Settings, X, Check, AlertCircle, Calendar,
    MessageSquare, Users,  CheckCircle
} from 'lucide-react';

const NotificationCenter = ({onClose}) => {

    return (
        <div className="fixed inset-y-0 right-0 w-96 bg-white border-l shadow-lg z-10">
            {/* Header */}
            <div className="p-4 border-b">
                <div className="flex items-center justify-between mb-4">
                    <h2 className="text-lg font-semibold">Notifications</h2>
                    <div className="flex items-center space-x-2">
                        <button className="p-2 text-gray-400 hover:text-gray-600 rounded" onClick={() => console.error("asdasd")}>
                            <Settings className="h-5 w-5" />
                        </button>
                        <button className="p-2 text-gray-400 hover:text-gray-600 rounded" onClick={() => onClose(true)}>
                            <X className="h-5 w-5" />
                        </button>
                    </div>
                </div>

                {/* Filters */}
                <div className="flex items-center space-x-2">
                    <button className="px-3 py-1.5 bg-blue-50 text-blue-600 rounded-lg text-sm">
                        All
                    </button>
                    <button className="px-3 py-1.5 text-gray-600 hover:bg-gray-100 rounded-lg text-sm">
                        Unread
                    </button>
                    <button className="px-3 py-1.5 text-gray-600 hover:bg-gray-100 rounded-lg text-sm">
                        Mentions
                    </button>
                </div>
            </div>

            {/* Notifications List */}
            <div className="overflow-y-auto h-[calc(100vh-100px)]">
                {/* Today */}
                <div className="py-4">
                    <h3 className="px-4 text-sm font-medium text-gray-500 mb-2">Today</h3>
                    <div className="space-y-1">
                        {/* Unread Notification */}
                        <div className="px-4 py-3 bg-blue-50 hover:bg-blue-100 cursor-pointer">
                            <div className="flex items-start">
                                <div className="flex-shrink-0 mt-1">
                                    <div className="h-8 w-8 bg-blue-500 rounded-full flex items-center justify-center">
                                        <MessageSquare className="h-4 w-4 text-white" />
                                    </div>
                                </div>
                                <div className="ml-3 flex-1">
                                    <p className="text-sm font-medium">{`New comment on "Project Timeline"`}</p>
                                    <p className="text-sm text-gray-600">
                                        {`Sarah Chen commented on your task: "Let's discuss this in the next meeting..."`}
                                    </p>
                                    <div className="flex items-center mt-2 space-x-4">
                                        <span className="text-xs text-gray-500">2 hours ago</span>
                                        <button className="text-xs text-blue-600 hover:text-blue-700">
                                            View Comment
                                        </button>
                                    </div>
                                </div>
                                <div className="ml-3 flex-shrink-0">
                                    <div className="h-2 w-2 bg-blue-600 rounded-full"></div>
                                </div>
                            </div>
                        </div>

                        {/* Meeting Notification */}
                        <div className="px-4 py-3 hover:bg-gray-50 cursor-pointer">
                            <div className="flex items-start">
                                <div className="flex-shrink-0 mt-1">
                                    <div className="h-8 w-8 bg-purple-100 rounded-full flex items-center justify-center">
                                        <Calendar className="h-4 w-4 text-purple-600" />
                                    </div>
                                </div>
                                <div className="ml-3 flex-1">
                                    <p className="text-sm font-medium">Upcoming Meeting Reminder</p>
                                    <p className="text-sm text-gray-600">
                                        {`"Team Sync" starts in 30 minutes`}
                                    </p>
                                    <div className="flex items-center mt-2 space-x-4">
                                        <span className="text-xs text-gray-500">30 minutes ago</span>
                                        <button className="text-xs text-blue-600 hover:text-blue-700">
                                            Join Meeting
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Task Completion */}
                        <div className="px-4 py-3 hover:bg-gray-50 cursor-pointer">
                            <div className="flex items-start">
                                <div className="flex-shrink-0 mt-1">
                                    <div className="h-8 w-8 bg-green-100 rounded-full flex items-center justify-center">
                                        <CheckCircle className="h-4 w-4 text-green-600" />
                                    </div>
                                </div>
                                <div className="ml-3 flex-1">
                                    <p className="text-sm font-medium">Task Completed</p>
                                    <p className="text-sm text-gray-600">
                                        {`Alex completed "Update documentation"`}
                                    </p>
                                    <div className="flex items-center mt-2">
                                        <span className="text-xs text-gray-500">1 hour ago</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Earlier */}
                <div className="py-4">
                    <h3 className="px-4 text-sm font-medium text-gray-500 mb-2">Earlier</h3>
                    <div className="space-y-1">
                        {/* Task Assignment */}
                        <div className="px-4 py-3 hover:bg-gray-50 cursor-pointer">
                            <div className="flex items-start">
                                <div className="flex-shrink-0 mt-1">
                                    <div className="h-8 w-8 bg-orange-100 rounded-full flex items-center justify-center">
                                        <Users className="h-4 w-4 text-orange-600" />
                                    </div>
                                </div>
                                <div className="ml-3 flex-1">
                                    <p className="text-sm font-medium">New Task Assignment</p>
                                    <p className="text-sm text-gray-600">
                                        {`Michael assigned you to "Review design mockups"`}
                                    </p>
                                    <div className="flex items-center mt-2 space-x-4">
                                        <span className="text-xs text-gray-500">Yesterday</span>
                                        <button className="text-xs text-blue-600 hover:text-blue-700">
                                            View Task
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Mention */}
                        <div className="px-4 py-3 hover:bg-gray-50 cursor-pointer">
                            <div className="flex items-start">
                                <div className="flex-shrink-0 mt-1">
                                    <div className="h-8 w-8 bg-blue-100 rounded-full flex items-center justify-center">
                                        <MessageSquare className="h-4 w-4 text-blue-600" />
                                    </div>
                                </div>
                                <div className="ml-3 flex-1">
                                    <p className="text-sm font-medium">Mentioned in a comment</p>
                                    <p className="text-sm text-gray-600">
                                        {`"@John What do you think about the new feature?"`}
                                    </p>
                                    <div className="flex items-center mt-2 space-x-4">
                                        <span className="text-xs text-gray-500">2 days ago</span>
                                        <button className="text-xs text-blue-600 hover:text-blue-700">
                                            Reply
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <div className="absolute bottom-0 left-0 right-0 border-t bg-white p-4">
                <div className="flex items-center justify-between">
                    <button className="text-sm text-gray-600 hover:text-gray-800">
                        Mark all as read
                    </button>
                    <button className="text-sm text-blue-600 hover:text-blue-700">
                        See all notifications
                    </button>
                </div>
            </div>
        </div>
    );
};

// Real-time Toast Notifications
const ToastNotification = ({ type, message, action, onClose }) => {
    const types = {
        success: {
            icon: Check,
            className: 'bg-green-50 border-green-200',
            iconClass: 'text-green-500',
            textClass: 'text-green-800'
        },
        error: {
            icon: AlertCircle,
            className: 'bg-red-50 border-red-200',
            iconClass: 'text-red-500',
            textClass: 'text-red-800'
        },
        info: {
            icon: AlertCircle,
            className: 'bg-blue-50 border-blue-200',
            iconClass: 'text-blue-500',
            textClass: 'text-blue-800'
        }
    };

    const { icon: Icon, className, iconClass, textClass } = types[type];
    const handleClose = () => {
        alert()
        if (onClose){
            onClose(true);
        }
    }

    return (
        <div className={`fixed top-1 right-1 max-w-sm w-full bg-white border rounded-lg shadow-lg ${className}`}>
            <div className="p-4">
                <div className="flex items-start">
                    <div className="flex-shrink-0"  >
                        <button onClick={handleClose}>
                            <Icon className={`h-5 w-5 ${iconClass}`} />
                        </button>
                    </div>
                    <div className="ml-3 w-0 flex-1">
                        <p className={`text-sm font-medium ${textClass}`}>{message}</p>
                        {action && (
                            <div className="mt-2 flex space-x-3">
                                <button className="text-sm font-medium text-blue-600 hover:text-blue-500">
                                    {action}
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="ml-4 flex-shrink-0 flex">
                        <button className="inline-flex text-gray-400 hover:text-gray-500">
                            <X className="h-5 w-5" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Notification Badge
const NotificationBadge = () => {
    return (
        <button className="relative p-2 text-gray-400 hover:text-gray-600 rounded-lg">
            <Bell className="h-6 w-6" />
            <span className="absolute top-1 right-1 h-4 w-4 bg-red-500 rounded-full flex items-center justify-center">
        <span className="text-xs text-white">3</span>
      </span>
        </button>
    );
};

export { NotificationCenter, ToastNotification, NotificationBadge };
