import React, { useState, useCallback, useEffect } from "react";
import { Check, ChevronsUpDown, Loader2, Plus } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import api from "@/config/axios.js";

export function ComboboxDemo({ label, workspaces = [], onWorkspaceSelect }) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const selectedWorkspace = workspaces.find(
      (workspace) => workspace.isCurrentlySelected,
    );
    if (selectedWorkspace) {
      setValue(selectedWorkspace.id);
    }
  }, [workspaces]);

  const handleWorkspaceSelect = useCallback(
    async (currentValue) => {
      const newValue = currentValue === value ? "" : currentValue;
      setValue(newValue);
      setOpen(false);

      try {
        if (newValue) {
          const updatedWorkspace = await api.post(
            `/workspace/set-current-workspace`,
            {
              workspaceId: newValue,
            },
          );
          console.log("updatedWorkspace", updatedWorkspace);
        }
      } catch (error) {
        console.error("Failed to update workspace selection:", error);
      }

      // Call the onWorkspaceSelect prop if provided
      if (onWorkspaceSelect) {
        onWorkspaceSelect(newValue);
      }
    },
    [value, onWorkspaceSelect],
  );

  const resetForm = useCallback(() => {
    setFormData({ name: "", description: "" });
    setErrors({});
    setIsSubmitting(false);
  }, []);

  const handleInputChange = useCallback(
    (e) => {
      const { id, value } = e.target;
      const field = id.split("-")[1];

      setFormData((prev) => ({
        ...prev,
        [field]: value,
      }));

      if (errors[field]) {
        setErrors((prev) => ({
          ...prev,
          [field]: "",
        }));
      }
    },
    [errors],
  );

  const validateForm = useCallback(() => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Workspace name is required";
    }

    if (!formData.description.trim()) {
      newErrors.description = "Workspace description is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData]);

  const handleCreateWorkspace = async () => {
    if (!validateForm()) return;

    setIsSubmitting(true);

    try {
      const response = await api.post("/workspace/workspaces", {
        name: formData.name,
        description: formData.description,
      });

      setShowAddModal(false);
      resetForm();

      // Optionally select the newly created workspace
      if (response?.id) {
        handleWorkspaceSelect(response?.id);
      }
    } catch (error) {
      console.error("Failed to create workspace:", error?.message);
      setErrors((prev) => ({
        ...prev,
        submit: error?.message,
      }));
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleModalClose = useCallback(() => {
    setShowAddModal(false);
    resetForm();
  }, [resetForm]);

  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-[250px] justify-between bg-gray-50 border-gray-200 hover:bg-gray-100"
          >
            {value
              ? workspaces.find((workspace) => workspace.id === value)?.name
              : label}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[300px] p-0">
          <Command className="rounded-lg border shadow-md">
            <div className="flex items-center p-2 gap-2 border-b">
              <div className="flex-1 relative">
                <CommandInput placeholder="Search" className="h-9 px-3" />
              </div>
              <Dialog open={showAddModal} onOpenChange={handleModalClose}>
                <Button
                  className="bg-gray-900 text-white hover:bg-gray-800"
                  onClick={() => setShowAddModal(true)}
                >
                  <Plus className="h-4 w-4" />
                </Button>
                <DialogContent className="sm:max-w-[425px]">
                  <DialogHeader>
                    <DialogTitle>Create New Workspace</DialogTitle>
                    <DialogDescription>
                      Add a new workspace to your account. Click save when
                      you're done.
                    </DialogDescription>
                  </DialogHeader>
                  <div className="grid gap-4 py-4">
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="workspace-name" className="text-right">
                        Name
                      </Label>
                      <div className="col-span-3">
                        <Input
                          id="workspace-name"
                          value={formData.name}
                          onChange={handleInputChange}
                          className={cn(
                            errors.name &&
                              "border-red-500 focus-visible:ring-red-500",
                          )}
                          placeholder="My Workspace"
                          disabled={isSubmitting}
                        />
                        {errors.name && (
                          <span className="text-red-500 text-sm mt-1">
                            {errors.name}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label
                        htmlFor="workspace-description"
                        className="text-right"
                      >
                        Description
                      </Label>
                      <div className="col-span-3">
                        <Textarea
                          id="workspace-description"
                          value={formData.description}
                          onChange={handleInputChange}
                          className={cn(
                            errors.description &&
                              "border-red-500 focus-visible:ring-red-500",
                          )}
                          placeholder="Workspace Description"
                          disabled={isSubmitting}
                        />
                        {errors.description && (
                          <span className="text-red-500 text-sm mt-1">
                            {errors.description}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {errors.submit && (
                    <div className="text-red-500 text-sm text-center mb-4">
                      {errors.submit}
                    </div>
                  )}
                  <DialogFooter>
                    <Button
                      type="submit"
                      onClick={handleCreateWorkspace}
                      className="bg-gray-900 text-white hover:bg-gray-800 w-full"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <Loader2 className="w-6 h-6 animate-spin mx-auto" />
                      ) : (
                        "Create Workspace"
                      )}
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            </div>
            <CommandList>
              <CommandEmpty>No workspace found.</CommandEmpty>
              <CommandGroup>
                {workspaces.map((workspace) => (
                  <CommandItem
                    key={workspace.id}
                    value={workspace.id}
                    onSelect={handleWorkspaceSelect}
                    className="hover:bg-gray-100"
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        value === workspace.id ? "opacity-100" : "opacity-0",
                      )}
                    />
                    {workspace.name}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </>
  );
}

export default ComboboxDemo;
