import {createContext, useContext} from "react";

const SubscriptionContext = createContext();

export const SubscriptionProvider = ({children}) => {
  const value = {
    subscription: {
      plan: 'Free',
      status: 'Active',
      nextBilling: '2022-01-01',
      lastPayment: '2021-01-01',
      paymentMethod: {
        type: 'Visa',
        last4: '1234',
        expires: '12/23',
      },
    },
    invoices: [],
    billingHistory: [],
    trailSubscription: () => {},
    getSubscription: () => {},
    getInvoices: () => {},
    getBillingHistory: () => {},
    upgradeSubscription: () => {},
    downgradeSubscription: () => {},
    cancelSubscription: () => {},
  };
  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  )
}

export const useSubscription = () => {
  const context = useContext(SubscriptionContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
