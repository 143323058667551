import SettingsLayout from "@/layouts/SettingsLayout.jsx";

const MeetingSettings = () => {
    return (
      <SettingsLayout>
        <div className="p-6">
            <div className="max-w-4xl mx-auto space-y-8">
                {/* General Settings */}
                <div className="bg-white rounded-lg border p-6">
                    <h2 className="text-lg font-medium mb-6">General Meeting Settings</h2>
                    <div className="space-y-6">
                        {/* Default Duration */}
                        <div className="flex items-center justify-between">
                            <div>
                                <p className="font-medium">Default Meeting Duration</p>
                                <p className="text-sm text-gray-500 mt-1">
                                    Set the default duration for new meetings
                                </p>
                            </div>
                            <select className="px-3 py-2 bg-white border rounded-lg">
                                <option>30 minutes</option>
                                <option>45 minutes</option>
                                <option>60 minutes</option>
                                <option>Custom</option>
                            </select>
                        </div>

                        {/* Time Zone */}
                        <div className="flex items-center justify-between">
                            <div>
                                <p className="font-medium">Time Zone</p>
                                <p className="text-sm text-gray-500 mt-1">
                                    Set your preferred time zone for meetings
                                </p>
                            </div>
                            <select className="px-3 py-2 bg-white border rounded-lg">
                                <option>(GMT-08:00) Pacific Time</option>
                                <option>(GMT-05:00) Eastern Time</option>
                                <option>(GMT+00:00) UTC</option>
                            </select>
                        </div>

                        {/* Calendar Integration */}
                        <div className="flex items-center justify-between">
                            <div>
                                <p className="font-medium">Default Calendar</p>
                                <p className="text-sm text-gray-500 mt-1">
                                    Choose your primary calendar for meetings
                                </p>
                            </div>
                            <select className="px-3 py-2 bg-white border rounded-lg">
                                <option>My Calendar</option>
                                <option>Team Calendar</option>
                                <option>Project Calendar</option>
                            </select>
                        </div>
                    </div>
                </div>

                {/* Notification Preferences */}
                <div className="bg-white rounded-lg border p-6">
                    <h2 className="text-lg font-medium mb-6">Notification Preferences</h2>
                    <div className="space-y-6">
                        <div className="space-y-4">
                            <label className="flex items-center justify-between">
                                <div>
                                    <span className="font-medium">Email Notifications</span>
                                    <p className="text-sm text-gray-500 mt-1">
                                        Receive meeting invites and updates via email
                                    </p>
                                </div>
                                <div className="relative">
                                    <input type="checkbox" className="sr-only" defaultChecked />
                                    <div className="w-11 h-6 bg-blue-600 rounded-full"></div>
                                    <div className="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                                </div>
                            </label>

                            <label className="flex items-center justify-between">
                                <div>
                                    <span className="font-medium">Desktop Notifications</span>
                                    <p className="text-sm text-gray-500 mt-1">
                                        Show desktop alerts for upcoming meetings
                                    </p>
                                </div>
                                <div className="relative">
                                    <input type="checkbox" className="sr-only" defaultChecked />
                                    <div className="w-11 h-6 bg-blue-600 rounded-full"></div>
                                    <div className="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                                </div>
                            </label>

                            <label className="flex items-center justify-between">
                                <div>
                                    <span className="font-medium">Reminder Notifications</span>
                                    <p className="text-sm text-gray-500 mt-1">
                                        Get reminded before meetings start
                                    </p>
                                </div>
                                <select className="px-3 py-2 bg-white border rounded-lg">
                                    <option>5 minutes before</option>
                                    <option>10 minutes before</option>
                                    <option>15 minutes before</option>
                                    <option>30 minutes before</option>
                                </select>
                            </label>
                        </div>
                    </div>
                </div>

                {/* Video Conference Settings */}
                <div className="bg-white rounded-lg border p-6">
                    <h2 className="text-lg font-medium mb-6">Video Conference Settings</h2>
                    <div className="space-y-6">
                        {/* Default Platform */}
                        <div className="flex items-center justify-between">
                            <div>
                                <p className="font-medium">Default Platform</p>
                                <p className="text-sm text-gray-500 mt-1">
                                    Choose your preferred video conferencing platform
                                </p>
                            </div>
                            <select className="px-3 py-2 bg-white border rounded-lg">
                                <option>Google Meet</option>
                                <option>Zoom</option>
                                <option>Microsoft Teams</option>
                            </select>
                        </div>

                        {/* Join Settings */}
                        <div className="space-y-4">
                            <label className="flex items-center justify-between">
                                <div>
                                    <span className="font-medium">Auto-join Audio</span>
                                    <p className="text-sm text-gray-500 mt-1">
                                        Automatically join audio when entering meetings
                                    </p>
                                </div>
                                <div className="relative">
                                    <input type="checkbox" className="sr-only" defaultChecked />
                                    <div className="w-11 h-6 bg-blue-600 rounded-full"></div>
                                    <div className="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                                </div>
                            </label>

                            <label className="flex items-center justify-between">
                                <div>
                                    <span className="font-medium">Auto-enable Video</span>
                                    <p className="text-sm text-gray-500 mt-1">
                                        Automatically enable video when joining meetings
                                    </p>
                                </div>
                                <div className="relative">
                                    <input type="checkbox" className="sr-only" />
                                    <div className="w-11 h-6 bg-gray-200 rounded-full"></div>
                                    <div className="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>

                {/* Recording Settings */}
                <div className="bg-white rounded-lg border p-6">
                    <h2 className="text-lg font-medium mb-6">Recording Settings</h2>
                    <div className="space-y-6">
                        <div className="space-y-4">
                            <label className="flex items-center justify-between">
                                <div>
                                    <span className="font-medium">Auto-record Meetings</span>
                                    <p className="text-sm text-gray-500 mt-1">
                                        Automatically record all meetings
                                    </p>
                                </div>
                                <div className="relative">
                                    <input type="checkbox" className="sr-only" />
                                    <div className="w-11 h-6 bg-gray-200 rounded-full"></div>
                                    <div className="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                                </div>
                            </label>

                            <div>
                                <p className="font-medium">Recording Storage</p>
                                <p className="text-sm text-gray-500 mt-1">
                                    Choose where to store meeting recordings
                                </p>
                                <div className="mt-2 space-y-2">
                                    <label className="flex items-center">
                                        <input
                                            type="radio"
                                            name="storage"
                                            className="h-4 w-4 text-blue-600"
                                            defaultChecked
                                        />
                                        <span className="ml-2 text-sm text-gray-600">
                      Cloud Storage (Recommended)
                    </span>
                                    </label>
                                    <label className="flex items-center">
                                        <input
                                            type="radio"
                                            name="storage"
                                            className="h-4 w-4 text-blue-600"
                                        />
                                        <span className="ml-2 text-sm text-gray-600">
                      Local Storage
                    </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Privacy Settings */}
                <div className="bg-white rounded-lg border p-6">
                    <div className="flex items-center justify-between mb-6">
                        <h2 className="text-lg font-medium">Privacy Settings</h2>
                        <button className="text-sm text-blue-600 hover:text-blue-700">
                            View Privacy Policy
                        </button>
                    </div>
                    <div className="space-y-6">
                        <div className="space-y-4">
                            <label className="flex items-center justify-between">
                                <div>
                                    <span className="font-medium">Waiting Room</span>
                                    <p className="text-sm text-gray-500 mt-1">
                                        Enable waiting room for all meetings
                                    </p>
                                </div>
                                <div className="relative">
                                    <input type="checkbox" className="sr-only" defaultChecked />
                                    <div className="w-11 h-6 bg-blue-600 rounded-full"></div>
                                    <div className="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                                </div>
                            </label>

                            <label className="flex items-center justify-between">
                                <div>
                                    <span className="font-medium">Private Meetings</span>
                                    <p className="text-sm text-gray-500 mt-1">
                                        Make all meetings private by default
                                    </p>
                                </div>
                                <div className="relative">
                                    <input type="checkbox" className="sr-only" defaultChecked />
                                    <div className="w-11 h-6 bg-blue-600 rounded-full"></div>
                                    <div className="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </SettingsLayout>
    );
};

export default MeetingSettings;
