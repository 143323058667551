import { DeviceUtils } from './device-utils.js';

export const SessionUtils = {
  // Constants for session management
  SESSION_KEYS: {
    TOKEN: 'taskweave_token',
    REFRESH_TOKEN: 'taskweave_refresh_token',
    DEVICE_ID: 'taskweave_device_id',
    REMEMBER_ME: 'taskweave_remember_me',
    SESSION_EXPIRY: 'taskweave_session_expiry'
  },

  // Set session with optional remember me
  setSession: (token, refreshToken, rememberMe = false) => {
    const storage = rememberMe ? localStorage : sessionStorage;

    // Store tokens
    storage.setItem(SessionUtils.SESSION_KEYS.TOKEN, token);
    storage.setItem(SessionUtils.SESSION_KEYS.REFRESH_TOKEN, refreshToken);
    storage.setItem(SessionUtils.SESSION_KEYS.REMEMBER_ME, rememberMe.toString());

    if (rememberMe) {
      // Set expiry to 1 week from now if remember me is true
      const expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 7);
      storage.setItem(SessionUtils.SESSION_KEYS.SESSION_EXPIRY, expiryDate.toISOString());
    }

    // Always store device ID in localStorage for consistency
    const deviceId = DeviceUtils.getDeviceId();
    localStorage.setItem(SessionUtils.SESSION_KEYS.DEVICE_ID, deviceId);
  },

  // Get session information
  getSession: () => {
    // Check both storage types
    const rememberedSession = SessionUtils.getRememberedSession();
    const temporarySession = SessionUtils.getTemporarySession();

    // Return remembered session if valid, otherwise temporary session
    return rememberedSession || temporarySession;
  },

  // Get remembered session (localStorage)
  getRememberedSession: () => {
    const token = localStorage.getItem(SessionUtils.SESSION_KEYS.TOKEN);
    const refreshToken = localStorage.getItem(SessionUtils.SESSION_KEYS.REFRESH_TOKEN);
    const expiryDate = localStorage.getItem(SessionUtils.SESSION_KEYS.SESSION_EXPIRY);

    if (!token || !refreshToken || !expiryDate) {
      return null;
    }

    // Check if session has expired
    if (new Date(expiryDate) < new Date()) {
      SessionUtils.clearSession();
      return null;
    }

    return {
      token,
      refreshToken,
      isRemembered: true,
      expiryDate: new Date(expiryDate)
    };
  },

  // Get temporary session (sessionStorage)
  getTemporarySession: () => {
    const token = sessionStorage.getItem(SessionUtils.SESSION_KEYS.TOKEN);
    const refreshToken = sessionStorage.getItem(SessionUtils.SESSION_KEYS.REFRESH_TOKEN);

    if (!token || !refreshToken) {
      return null;
    }

    return {
      token,
      refreshToken,
      isRemembered: false,
      expiryDate: null
    };
  },

  // Extend session if it's a remembered session
  extendSession: () => {
    const rememberedSession = SessionUtils.getRememberedSession();

    if (rememberedSession) {
      // Extend expiry to 1 week from now
      const expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 7);
      localStorage.setItem(SessionUtils.SESSION_KEYS.SESSION_EXPIRY, expiryDate.toISOString());
      return true;
    }
    return false;
  },

  // Clear session from both storage types
  clearSession: () => {
    // Clear localStorage
    localStorage.removeItem(SessionUtils.SESSION_KEYS.TOKEN);
    localStorage.removeItem(SessionUtils.SESSION_KEYS.REFRESH_TOKEN);
    localStorage.removeItem(SessionUtils.SESSION_KEYS.REMEMBER_ME);
    localStorage.removeItem(SessionUtils.SESSION_KEYS.SESSION_EXPIRY);

    // Clear sessionStorage
    sessionStorage.removeItem(SessionUtils.SESSION_KEYS.TOKEN);
    sessionStorage.removeItem(SessionUtils.SESSION_KEYS.REFRESH_TOKEN);

    // Keep device ID for consistency
  }

};
