import React from "react";
import { Bell, Menu, Plus, Search } from "lucide-react";
import { Link as RouterLink } from "react-router-dom";
import { NotificationCenter } from "../../notifications/NotificationCenter.jsx";
import ProfileDropdown from "./profileDropdown.jsx";
import { useAuth } from "@/contexts/AuthContext.jsx";
import ComboboxDemo from "@/components/ui/comboBox.jsx";

export default function AuthHeader() {
  const { user } = useAuth();
  console.log(user?.workspaces);

  const [showNotifications, setShowNotifications] = React.useState(false);
  return (
    <header className=" bg-white border-b flex items-center justify-between px-4">
      {showNotifications && (
        <NotificationCenter onClose={() => setShowNotifications(false)} />
      )}
      <div className="flex items-center space-x-4">
        <button className="focus:outline-none">
          <Menu className="h-6 w-6 text-gray-500 cursor-pointer" />
        </button>
      </div>

      <div className="flex items-center space-x-4">
        <ComboboxDemo
          label="Select Workspace"
          workspaces={user?.workspaces}
          onWorkspaceSelect={(workspaceId) => {
            console.log("Selected workspace:", workspaceId);
          }}
        />

        <button
          className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg focus:outline-none"
          aria-label="Notifications"
          onClick={() => setShowNotifications(!showNotifications)}
        >
          <Bell className="w-6 h-6" />
        </button>
      </div>
    </header>
  );
}
