import {
    HelpCircle,
} from 'lucide-react';
import { Link as RouterLink } from 'react-router-dom';
import {sidebarRoutes} from "../router/all.routers";
import AuthHeader from "../components/common/layout/AuthHeader.jsx";
import ProfileDropdown from "@/components/common/layout/profileDropdown.jsx";
import React from "react";

const MainLayout = ({children}) => {
    const currentPath = window.location.pathname;
    return (
        <div className="flex h-screen">
            {/* Main Navigation Sidebar */}
            <aside className="w-16 bg-gray-900 flex flex-col items-center py-4">
                <nav className="flex-1 w-full">
                    <ul className="flex flex-col items-center space-y-4">
                        <li>
                            <ProfileDropdown />
                        </li>
                        {sidebarRoutes.map((item) => (
                          item.location === "sideBar" && (
                            <li key={item.path} className="w-full">
                                <RouterLink
                                  to={item.path}
                                  className={`flex items-center justify-center p-3 text-gray-400 ${currentPath === item.path ? 'text-white bg-gray-800' : 'hover:text-white hover:bg-gray-800'} rounded-lg mx-2`}
                                  title={item.label}
                                >
                                    <item.icon className="w-6 h-6"/>
                                </RouterLink>
                            </li>
                          )
                        ))}
                    </ul>
                </nav>

                <div className="mt-auto mb-4">
                    <RouterLink
                      to="/help"
                      className="flex items-center justify-center p-3 text-gray-400 hover:text-white hover:bg-gray-800 rounded-lg mx-2"
                      title="Help & Support"
                    >
                        <HelpCircle className="w-6 h-6"/>
                    </RouterLink>
                </div>
            </aside>

            {/* Main Content Area */}
            <main className="flex-1 flex flex-col bg-gray-50">
                {/* Top Header */}
                <AuthHeader/>
                {/* Page Content with Routes */}
                <div className="flex-1 overflow-auto p-6">
                    {children}
                </div>
            </main>
        </div>
    );
};

export default MainLayout;
