export const sampleMeetings = [
  {
    id: 1,
    title: 'Weekly Team Sync',
    date: new Date(2024, 10, 5), // Remember months are 0-based in JS
    startTime: '10:00',
    endTime: '11:00',
    type: { label: 'Team Sync', emoji: '👥' },
    platform: 'Google Meet',
    participants: ['john@example.com', 'sarah@example.com', 'mike@example.com'],
    isRecurring: true,
    description: 'Weekly team sync to discuss project progress and blockers',
    seriesId: 1
  },
  {
    id: 2,
    title: 'Product Design Review',
    date: new Date(2024, 10, 5),
    startTime: '14:00',
    endTime: '15:30',
    type: { label: 'Design', emoji: '🎨' },
    platform: 'Zoom',
    participants: ['design@example.com', 'pm@example.com'],
    isRecurring: false,
    description: 'Review new feature designs and collect feedback',
    seriesId: 1
  },
  {
    id: 3,
    title: 'Client Presentation',
    date: new Date(2024, 10, 6),
    startTime: '11:00',
    endTime: '12:00',
    type: { label: 'Planning', emoji: '🎯' },
    platform: 'Google Meet',
    participants: ['client@example.com', 'sales@example.com'],
    isRecurring: false,
    description: 'Present Q4 results to the client',
    seriesId: 1
  },
  {
    id: 4,
    title: '1:1 with Manager',
    date: new Date(2024, 10, 7),
    startTime: '15:00',
    endTime: '15:30',
    type: { label: 'One on One', emoji: '💬' },
    platform: 'Microsoft Teams',
    participants: ['manager@example.com', 'me@example.com'],
    isRecurring: true,
    description: 'Weekly one on one catch-up'
  },
  {
    id: 5,
    title: 'Project Kickoff',
    date: new Date(2024, 10, 8),
    startTime: '09:00',
    endTime: '10:30',
    type: { label: 'Planning', emoji: '🎯' },
    platform: 'Zoom',
    participants: ['team@example.com', 'stakeholders@example.com'],
    isRecurring: false,
    description: 'New project kickoff meeting'
  }
];
