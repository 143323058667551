import {
    User, Bell, Lock, Users, Globe, Laptop,
    CreditCard, HelpCircle, Mail, Search
} from 'lucide-react';
import {Link, useLocation} from "react-router-dom";

const headerInfo = [
    {
        title: "Profile",
        description: "Manage your account settings and profile information",
        icon: User,
        link: "/settings",
        section: "Account"
    },
    {
        title: "Notifications",
        description: "Manage your email and in-app notifications",
        icon: Bell,
        link: "/notifications",
        section: "Account"
    },
    {
        title: "Security",
        description: "Manage your password, two-factor authentication, and security settings",
        icon: Lock,
        link: "/security",
        section: "Account"
    },
    {
        title: "Team Members",
        description: "Manage your team members and their permissions",
        icon: Users,
        link: "/teams",
        section: "Workspace"
    },
    // {
    //     title: "Language & Region",
    //     description: "Manage your language, timezone, and region settings",
    //     icon: Globe,
    //     link: "/language",
    //     section: "Workspace"
    // },
    {
        title: "Meeting Settings",
        description: "Manage your meeting settings and preferences",
        icon: Globe,
        link: "/meeting-settings",
        section: "Workspace"
    },
    // {
    //     title: "Appearance",
    //     description: "Manage your theme, layout, and other appearance settings",
    //     icon: Laptop,
    //     link: "/appearance",
    //     section: "Preferences"
    // },
    {
        title: "Email Settings",
        description: "Manage your email notifications and preferences",
        icon: Mail,
        link: "/email-settings",
        section: "Preferences"
    },
    {
        title: "Plans & Billing",
        description: "Manage your subscription, billing information, and payment methods",
        icon: CreditCard,
        link: "/billings",
        section: "Billing"
    },
    {
        title: "Help & Support",
        description: "Get help, contact support, and view resources",
        icon: HelpCircle,
        link: "/help",
        section: "Support"
    },
]

const SettingsLayout = ({children}) => {
    const path = useLocation().pathname;
    return (
      <div className="min-h-screen bg-gray-50">

          {/* Content */}
          <div className="max-w-7xl mx-auto px-6 py-8">
              <div className="grid grid-cols-12 gap-8">
                  {/* Sidebar Navigation */}
                  <div className="col-span-2">
                      <nav className="space-y-1">
                          <div>
                              <h2 className="text-xs font-semibold text-gray-500 uppercase tracking-wider mb-4">
                                  Account Settings
                              </h2>

                              {/* Account Section */}
                              {headerInfo.map((item, index) => {
                                  if (item.section === "Account") {
                                      return (
                                        <Link
                                          to={item.link}
                                          key={index}
                                          className={`flex items-center w-full px-3 py-2 text-sm font-medium rounded-lg ${item.link === path ? "text-blue-600 bg-blue-50" : "text-gray-600 hover:bg-gray-100"}`}
                                        >
                                            <item.icon className="h-4 w-4 mr-3"/>
                                            {item.title}
                                        </Link>
                                      )
                                  }
                              })}
                          </div>
                          <div className="pt-4">
                              <h2 className="text-xs font-semibold text-gray-500 uppercase tracking-wider mb-4">
                                  Workspace Settings
                              </h2>

                              {/* Workspace Section */}
                              {headerInfo.map((item, index) => {
                                  if (item.section === "Workspace") {
                                      return (
                                        <Link
                                          to={item.link}
                                          key={index}
                                          className={`flex items-center w-full px-3 py-2 text-sm font-medium rounded-lg ${item.link === path ? "text-blue-600 bg-blue-50" : "text-gray-600 hover:bg-gray-100"}`}
                                        >
                                            <item.icon className="h-4 w-4 mr-3"/>
                                            {item.title}
                                        </Link>
                                      )
                                  }
                              })}
                          </div>
                          <div className="pt-4">
                              <h2 className="text-xs font-semibold text-gray-500 uppercase tracking-wider mb-4">
                                  Preferences
                              </h2>

                              {/* Preferences Section */}
                              {headerInfo.map((item, index) => {
                                  if (item.section === "Preferences") {
                                      return (
                                        <Link
                                          to={item.link}
                                          key={index}
                                          className={`flex items-center w-full px-3 py-2 text-sm font-medium rounded-lg ${item.link === path ? "text-blue-600 bg-blue-50" : "text-gray-600 hover:bg-gray-100"}`}
                                        >
                                            <item.icon className="h-4 w-4 mr-3"/>
                                            {item.title}
                                        </Link>
                                      )
                                  }
                              })}
                          </div>
                          <div className="pt-4">
                              <h2 className="text-xs font-semibold text-gray-500 uppercase tracking-wider mb-4">
                                  Billing
                              </h2>

                              {/* Billing Section */}
                              {headerInfo.map((item, index) => {
                                  if (item.section === "Billing") {
                                      return (
                                        <Link
                                          to={item.link}
                                          key={index}
                                          className={`flex items-center w-full px-3 py-2 text-sm font-medium rounded-lg ${item.link === path ? "text-blue-600 bg-blue-50" : "text-gray-600 hover:bg-gray-100"}`}
                                        >
                                            <item.icon className="h-4 w-4 mr-3"/>
                                            {item.title}
                                        </Link>
                                      )
                                  }
                              })}
                          </div>
                      </nav>
                  </div>

                  {/* Main Content Area */}
                  <div className="col-span-10 bg-white rounded-lg shadow-sm">
                      <div>
                          <div className="text-left text-gray-500 ">
                              {/* Header */}
                              <div className="bg-white border-b">
                                  <div className="px-6 py-4">
                                      <div className="flex items-center justify-between mb-4">
                                          {headerInfo.map((item, index) => {
                                              if (item.link === path) {
                                                  return (
                                                    <div key={index}>
                                                        <h1 className="text-xl font-semibold">{item.title} Settings</h1>
                                                        <p className="text-sm text-gray-500 mt-1">
                                                            {item.description}
                                                        </p>
                                                    </div>
                                                  )
                                              }
                                          })}
                                      </div>
                                  </div>
                              </div>

                              {children}
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    );
};

export default SettingsLayout;
