import { v4 as uuidv4 } from 'uuid';

export const DeviceUtils = {
  // Get or generate device ID
  getDeviceId: () => {
    let deviceId = localStorage.getItem('taskweave_device_id');

    if (!deviceId) {
      deviceId = DeviceUtils.generateDeviceId();
      localStorage.setItem('taskweave_device_id', deviceId);
    }

    return deviceId;
  },

  // Generate new device ID
  generateDeviceId: () => {
    const browser = DeviceUtils.getBrowserInfo();
    const os = DeviceUtils.getOSInfo();
    const uuid = uuidv4();

    return `${browser.name}_${os.name}_${uuid}`;
  },

  // Get browser information
  getBrowserInfo: () => {
    const ua = navigator.userAgent;
    let browserName = "unknown";
    let browserVersion = "unknown";

    // Chrome
    if (/Chrome/.test(ua) && !/Chromium/.test(ua)) {
      browserName = "chrome";
      browserVersion = ua.match(/Chrome\/(\d+\.\d+)/)?.[1] || "";
    }
    // Firefox
    else if (/Firefox/.test(ua)) {
      browserName = "firefox";
      browserVersion = ua.match(/Firefox\/(\d+\.\d+)/)?.[1] || "";
    }
    // Safari
    else if (/Safari/.test(ua) && !/Chrome/.test(ua)) {
      browserName = "safari";
      browserVersion = ua.match(/Version\/(\d+\.\d+)/)?.[1] || "";
    }
    // Edge
    else if (/Edg/.test(ua)) {
      browserName = "edge";
      browserVersion = ua.match(/Edg\/(\d+\.\d+)/)?.[1] || "";
    }

    return {
      name: browserName,
      version: browserVersion
    };
  },

  // Get OS information
  getOSInfo: () => {
    const ua = navigator.userAgent;
    let osName = "unknown";
    let osVersion = "unknown";

    // Windows
    if (/Windows/.test(ua)) {
      osName = "windows";
      osVersion = ua.match(/Windows NT (\d+\.\d+)/)?.[1] || "";
    }
    // macOS
    else if (/Mac OS X/.test(ua)) {
      osName = "macos";
      osVersion = ua.match(/Mac OS X (\d+[._]\d+)/)?.[1]?.replace('_', '.') || "";
    }
    // iOS
    else if (/iPhone|iPad|iPod/.test(ua)) {
      osName = "ios";
      osVersion = ua.match(/OS (\d+[._]\d+)/)?.[1]?.replace('_', '.') || "";
    }
    // Android
    else if (/Android/.test(ua)) {
      osName = "android";
      osVersion = ua.match(/Android (\d+\.\d+)/)?.[1] || "";
    }
    // Linux
    else if (/Linux/.test(ua)) {
      osName = "linux";
      osVersion = "";
    }

    return {
      name: osName,
      version: osVersion
    };
  },

  // Get device type
  getDeviceType: () => {
    const ua = navigator.userAgent;

    if (/Mobile|Android|iPhone|iPad|iPod/i.test(ua)) {
      return 'mobile';
    } else if (/Tablet|iPad/i.test(ua)) {
      return 'tablet';
    }
    return 'desktop';
  },

  // Get full device info
  getDeviceInfo: () => {
    const browser = DeviceUtils.getBrowserInfo();
    const os = DeviceUtils.getOSInfo();
    const deviceType = DeviceUtils.getDeviceType();

    return {
      deviceId: DeviceUtils.getDeviceId(),
      deviceType,
      browser: browser.name,
      browserVersion: browser.version,
      os: os.name,
      osVersion: os.version,
      userAgent: navigator.userAgent,
      screenResolution: `${window.screen.width}x${window.screen.height}`,
      language: navigator.language,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };
  }
};
