import React, { createContext, useContext, useState } from 'react';
import {sampleMeetings} from "@/models/sampleMeetings.js";
const MeetingContext = createContext(
  {
    meetings: [],
    addMeeting: () => {},
    updateMeeting: () => {},
    deleteMeeting: () => {},
    getMeeting: () => {},
    getSeriesById: () => {}
  }
);

export const MeetingProvider = ({ children }) => {
  const [meetings, setMeetings] = useState(sampleMeetings);

  const addMeeting = (meeting) => {
    setMeetings(prev => [...prev, { ...meeting, id: Date.now() }]);
  };

  const updateMeeting = (id, updatedMeeting) => {
    setMeetings(prev => prev.map(meeting =>
      meeting.id === id ? { ...meeting, ...updatedMeeting } : meeting
    ));
  };

  const deleteMeeting = (id) => {
    setMeetings(prev => prev.filter(meeting => meeting.id !== id));
  };

  const getMeeting = (id) => {
    return meetings.find(meeting => meeting.id === id);
  };

  const getSeriesById = (id) => {
    return meetings.filter(meeting => meeting.seriesId === id);
  }

  return (
    <MeetingContext.Provider value={{
      meetings,
      addMeeting,
      updateMeeting,
      deleteMeeting,
      getMeeting,
      getSeriesById
    }}>
      {children}
    </MeetingContext.Provider>
  );
};

export const useMeetings = () => useContext(MeetingContext);
